<template>
  <div class="daily-info two" v-if="model">
    <div class="item">
      <div class="item-text">
        <strong>{{ model.weatherDay }}</strong>
        <span>{{ model.weatherValue }}°</span>
      </div>
      <!-- <div class="image fixed-image">
        <img :src="imagePath" />
      </div> -->
      <div class="image fixed-image">
        <img :src="imagePath" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'weather2',
  props: ['data'],
  computed: {
    model() {
      return this.$store.state.weather;
    },
    imagePath() {
      return `/images/weather/${this.model.weatherIcon}`;
    }
  }
};
</script>

<style scoped></style>
